@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

body {
  font-family: "Sora", sans-serif;
}

.item-button button{
  margin-top: 20px;
  width: 180px;
  background-color: rgb(9, 47, 78);
  color: white;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.spinner-border{
  height: 100px;
  width: 100px;
}

.category{
  border: 2px solid gray;
  margin-right: 25px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.category img{
  margin-left: 10px;
}

.category-active{
  border: 2px solid rgb(9, 47, 78);
}


/*register page */

.register{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.register input{
  width: 400px !important;
}

.register-form {
  background-color: #c9caca34;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.register-form h1 {
  text-align: center;
  background-color: rgb(9, 47, 78);
  color: white;
  padding: 10px;
}

.register-form h3 {
  text-align: center;
}