#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
    box-shadow: 0 0 3px #ccc;
    border-radius: 5px;
    margin: 10px;
    overflow: auto;
  }
  
  .ant-layout-has-sider {
    padding: 5px !important;
  }
  
  .ant-layout.ant-layout-has-sider {
    height: 100vh;
  }
  
  .ant-layout-sider {
    border-radius: 10px !important;
    padding-top: 10px !important;
  }
  
  .anticon svg {
    height: 20px;
    width: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .ant-layout-header{
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cart-item{
    padding-left: 20px;
    padding-right: 30px;
    cursor: pointer;
  }

  .cart-item p{
    margin-top: 10px;
    font-weight: bold;
  }